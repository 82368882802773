import React from "react";

const Table = ({ companies }) => {
  return (
    <div
      className="list-container"
      style={{
        backgroundColor: companies.length > 1 ? "white" : "transparent",
        borderRadius: '15px'
      }}
    >
      <div className="table-scroll" style={{ overflow: 'auto' }}>
        <table style={{ borderRadius: '15px'}}>
          <tbody>
            {Object.keys(companies[0]).map((key, headerIndex) => (
              <tr key={key}>
                <th
                  className="sticky-header"
                  style={{
                    left: `0px`,
                  }}
                >
                  {key}
                </th>
                {companies.map((company, index) => (
                  <td key={index}>
                    {key === "FinalShariahStatus" ? (
                      <span
                        style={{
                          backgroundColor: company.FinalShariahStatus === "Compliant" ? "#3BFF72" : "#FF4242",
                          color: company.FinalShariahStatus === "Compliant" ? "black" : "white",
                          padding: "0.3rem 0.7rem",
                          borderRadius: "1rem",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {company.FinalShariahStatus}
                      </span>
                    ) : (
                      company[key]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
