import React, { useState } from "react";
import SearchBar from "./SearchBar";
import Table from "./Table";
import companiesData from "./companies.json";

const App = () => {
	const [filteredCompanies, setFilteredCompanies] = useState([]);
	const [isSearchPerformed, setIsSearchPerformed] = useState(false);

	const handleSearch = (searchTerm) => {
      let filtered;
	// Check if the searchTerm is empty
	if (!searchTerm) {
		// If empty, show all records
		filtered = companiesData;
	} else {
    // If searchTerm is not empty, filter as usual
    filtered = companiesData.filter(
      (company) => company.CompanyName && company.CompanyName === searchTerm
    );
   }
  setFilteredCompanies(filtered);
  setIsSearchPerformed(true);
};

	return (
		<div className="main-container">
			<SearchBar onSearch={handleSearch} companiesData={companiesData} />
			{isSearchPerformed &&
				(filteredCompanies.length > 0 ? (
					<Table companies={filteredCompanies} />
				) : (
					<div className="no-results">No company found</div>
				))}
		</div>
	);
};

export default App;
