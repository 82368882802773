import React, { useState} from "react";

function SearchBar({ onSearch, companiesData }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const handleSearch = (event) => {
    event.preventDefault();
    let searchTermToUse = searchTerm;
    // Check if there are suggestions and use the first one if available
    if (suggestions.length > 0) {
      searchTermToUse = suggestions[0].CompanyName;
    }
    onSearch(searchTermToUse);
    setSuggestions([]);
    setSearchTerm(""); // Reset searchTerm to clear the input field
  };


  const handleInputChange = (e) => {
    const value = e.target?.value?.trim()?.toLowerCase();
    setSearchTerm(value);
    setHighlightedIndex(-1); // Reset the highlighted index when input changes
    // This check ensures we don't perform unnecessary operations
    if (!value) {
      setSuggestions([]);
      return;
    }

    // Using a more efficient filtering and mapping process
    const filteredSuggestions = companiesData.reduce((acc, company) => {
      if (acc.length >= 5) return acc; // Stop adding to the array once we have 5 items
      if (
        company?.CompanyName &&
        (company?.CompanyName
          .replace(/\s+/g, "")
          ?.toLowerCase()
          ?.startsWith(value) ||
        company?.Ticker?.toLowerCase().startsWith(value))
      ) {
        acc.push(company);
      }
      return acc;
    }, []);
    setSuggestions(filteredSuggestions);
  };


  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(""); // Clear the search field after selection
    setSuggestions([]);
    onSearch(suggestion?.CompanyName); // Directly invoke the search with the selected company's name
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setHighlightedIndex((prevIndex) =>
        Math.min(prevIndex + 1, suggestions.length - 1)
      );
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (highlightedIndex > -1 && suggestions[highlightedIndex]) {
        handleSuggestionClick(suggestions[highlightedIndex]);
      } else {
        handleSearch(e);
      }
    }
  };

  return (
    <div className="search-bar-container">
      <h2 style={{color:"white"}}>PSX - Shariah Compliant Stocks</h2>
      <form className="search-bar" onSubmit={handleSearch}>
        <div className="input-wrapper">
          <input
            type="text"
            className="search-input"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown} // Add keydown handler to the input
          />
          <div className="search-bar-icon-wrapper" onClick={handleSearch}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.55762 11.8461C9.47835 11.8461 11.8461 9.47841 11.8461 6.55768C11.8461 3.63694 9.47835 1.26922 6.55762 1.26922C3.63688 1.26922 1.26916 3.63694 1.26916 6.55768C1.26916 9.47841 3.63688 11.8461 6.55762 11.8461ZM12.8076 6.55768C12.8076 10.0095 10.0094 12.8077 6.55762 12.8077C3.10584 12.8077 0.307617 10.0095 0.307617 6.55768C0.307617 3.1059 3.10584 0.307678 6.55762 0.307678C10.0094 0.307678 12.8076 3.1059 12.8076 6.55768Z"
                fill="white"
              />
              <path
                d="M10.2537 11.5982C10.282 11.6367 10.3136 11.6735 10.3484 11.7083L14.0508 15.4107C14.4263 15.7862 15.0351 15.7862 15.4106 15.4107C15.7861 15.0352 15.7861 14.4264 15.4106 14.0508L11.7082 10.3485C11.6734 10.3137 11.6366 10.2821 11.5982 10.2537C11.221 10.7673 10.7672 11.221 10.2537 11.5982Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </form>
      {suggestions.length > 0 && (
        <div className="suggestions-dropdown">
          <div className="suggestions-title">Suggestions</div>
          <ul>
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                style={{
                  backgroundColor:
                    index === highlightedIndex ? "#eee" : "transparent",
                }}
              >
                {`${suggestion?.CompanyName} - ${suggestion?.Ticker}`}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default SearchBar;
